@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 300;
  src: url("quicksand-v19-latin-300.5cc18421.eot");
  src: local(""), url("quicksand-v19-latin-300.5cc18421.eot#iefix") format("embedded-opentype"), url("quicksand-v19-latin-300.a25a16bb.woff2") format("woff2"), url("quicksand-v19-latin-300.6a2b8625.woff") format("woff"), url("quicksand-v19-latin-300.77d436c3.ttf") format("truetype"), url("quicksand-v19-latin-300.8470457c.svg#Quicksand") format("svg");
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  src: url("quicksand-v19-latin-500.301b1b64.eot");
  src: local(""), url("quicksand-v19-latin-500.301b1b64.eot#iefix") format("embedded-opentype"), url("quicksand-v19-latin-500.fc8423c1.woff2") format("woff2"), url("quicksand-v19-latin-500.9375f223.woff") format("woff"), url("quicksand-v19-latin-500.0c79c673.ttf") format("truetype"), url("quicksand-v19-latin-500.ef60a1b3.svg#Quicksand") format("svg");
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  src: url("quicksand-v19-latin-regular.e1ca0cd2.eot");
  src: local(""), url("quicksand-v19-latin-regular.e1ca0cd2.eot#iefix") format("embedded-opentype"), url("quicksand-v19-latin-regular.7787df62.woff2") format("woff2"), url("quicksand-v19-latin-regular.79de0411.woff") format("woff"), url("quicksand-v19-latin-regular.e49d7358.ttf") format("truetype"), url("quicksand-v19-latin-regular.81e9435a.svg#Quicksand") format("svg");
}

@keyframes distortRGB {
  0%, 93% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }

  95% {
    text-shadow: 6px 0 rgba(255, 0, 0, .5), 3px 0 rgba(0, 255, 0, .5), -3px 0 rgba(0, 0, 255, .5);
  }

  97%, 100% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3c3c3c;
  margin: 0;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: #3c3c3c;
  }
}

strong, h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

a {
  color: #3c3c3c;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  a {
    color: #fff;
  }
}

a:hover {
  color: #fff;
  background-color: #3c3c3c;
}

@media (prefers-color-scheme: dark) {
  a:hover {
    color: #3c3c3c;
    background-color: #fff;
  }
}

button {
  cursor: pointer;
  color: #3c3c3c;
  background-color: #fff;
  border: 0;
  outline: none;
  font-family: Quicksand, sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

button:focus {
  outline: 1px;
}

@media (prefers-color-scheme: dark) {
  button {
    color: #fff;
    background-color: #3c3c3c;
  }
}

button:hover {
  color: #fff;
  background-color: #3c3c3c;
}

@media (prefers-color-scheme: dark) {
  button:hover {
    color: #3c3c3c;
    background-color: #fff;
  }
}

.visually-hidden {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.app--static-viewport {
  margin-bottom: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.page {
  background-color: #fff;
  margin: 1rem auto;
}

@media (prefers-color-scheme: dark) {
  .page {
    background-color: #3c3c3c;
  }
}

.page__content {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 1rem;
}

@media screen and (max-width: 600px) {
  .page__content {
    border: 0;
  }
}

.page h1 {
  font-size: 1.75rem;
}

.page h1:first-child {
  margin-top: 0;
}

.page h1, .page h2, .page h3, .page h4, .page h5 {
  margin: 1rem 0;
}

.page p {
  margin: .5rem 0;
}

.page ul {
  margin-left: 1.5rem;
}

.page__go-back {
  text-align: center;
  padding-bottom: .5rem;
}

.page__go-back a {
  padding: .5rem .8rem;
  display: inline-block;
}

.page-enter {
  opacity: 0;
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.page-enter.page-enter-active {
  opacity: 1;
  transition: all .75s linear;
  transform: translateY(0);
}

.page-leave {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(0%);
}

.page-leave.page-leave-active {
  transition: all .75s linear;
  transform: translateX(100%);
}

.fade-enter {
  width: 0;
  display: inline-block;
}

.fade-enter.fade-enter-active {
  width: 60px;
  transition: width .25s linear;
}

@keyframes distortRGB {
  0%, 93% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }

  95% {
    text-shadow: 6px 0 rgba(255, 0, 0, .5), 3px 0 rgba(0, 255, 0, .5), -3px 0 rgba(0, 0, 255, .5);
  }

  97%, 100% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn3D {
  0% {
    opacity: 0;
    transform: translateZ(200px);
  }

  100% {
    opacity: 1;
    transform: translateZ(1px);
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blurBackgroundIn {
  0% {
    -webkit-backdrop-filter: blur();
    backdrop-filter: blur();
  }

  100% {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
}

@keyframes cardRotateIn {
  0% {
    transform: translate(-50%, -50%)rotateX(65deg)rotateY(-65deg)rotateZ(85deg);
  }

  100% {
    transform: translate(-50%, -50%)rotateX(0)rotateY(0)rotateZ(0);
  }
}

@keyframes cardRotateInSafari {
  0% {
    transform: translate(-50%, -50%)rotateX(65deg)rotateY(-65deg)rotateZ(85deg)translateZ(-200px);
  }

  100% {
    transform: translate(-50%, -50%)rotateX(0)rotateY(0)rotateZ(0)translateZ(-200px);
  }
}

.mouse-catcher {
  perspective: 800px;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.business-card {
  transform-style: preserve-3d;
  width: 490px;
  height: 240px;
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotateX(0)rotateY(0)rotateZ(0);
}

@media screen and (max-width: 600px) {
  .business-card {
    width: 250px;
    height: 350px;
  }
}

.business-card__backdrop-filter {
  background: rgba(255, 255, 255, .7);
  border: 1px solid #c8c8c8;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (prefers-color-scheme: dark) {
  .business-card__backdrop-filter {
    background: rgba(60, 60, 60, .8);
    border: 1px solid #fff;
    box-shadow: 0 0 20px rgba(255, 255, 255, .1);
  }
}

@supports ((-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px))) {
  .business-card__backdrop-filter {
    background: rgba(255, 255, 255, .25);
    animation-name: blurBackgroundIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 4.2s;
    animation-fill-mode: forwards;
  }

  @media (prefers-color-scheme: dark) {
    .business-card__backdrop-filter {
      background: rgba(60, 60, 60, .7);
    }
  }
}

.business-card__name {
  margin-bottom: .25rem;
  font-weight: 400;
}

.business-card .business-card__name, .business-card .business-card__title, .business-card .business-card__phone, .business-card .business-card__mail {
  transform: translateZ(1px);
}

.business-card__contact {
  text-align: right;
  transform-style: inherit;
  position: absolute;
  bottom: .8rem;
  right: 1rem;
}

.business-card__contact a {
  display: block;
}

.background {
  background-image: url("background.5a629feb.svg");
  background-repeat: repeat;
  position: absolute;
  top: -700px;
  bottom: -700px;
  left: -700px;
  right: -700px;
}

@media (prefers-color-scheme: dark) {
  .background {
    background-color: #3c3c3c;
    background-image: url("background-dark.d93fac76.svg");
  }
}

.background--safari {
  transform: translateZ(-200px);
}

.wrapper {
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.no-compat .wrapper, .edge-compat .wrapper, .ie-compat .wrapper {
  perspective: 800px;
  transform-origin: center center -200px;
}

.run-intro .wrapper {
  animation-name: cardFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.run-intro .background {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 2.2s;
  animation-fill-mode: forwards;
}

.run-intro .business-card {
  animation-name: cardRotateIn;
  animation-duration: 4.35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.run-intro .business-card__backdrop-filter {
  animation-delay: 4.2s;
}

.run-intro .business-card__name, .run-intro .business-card__title, .run-intro .business-card__phone, .run-intro .business-card__mail {
  opacity: 0;
  animation-name: fadeIn3D;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.run-intro .business-card__name {
  animation-delay: 1s;
}

.run-intro .business-card__title {
  animation-delay: 1.5s;
}

.run-intro .business-card__phone {
  animation-delay: 2s;
}

.run-intro .business-card__mail {
  animation-delay: 2.5s;
}

.wrapper, .background {
  will-change: transform;
}

@keyframes distortRGB {
  0%, 93% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }

  95% {
    text-shadow: 6px 0 rgba(255, 0, 0, .5), 3px 0 rgba(0, 255, 0, .5), -3px 0 rgba(0, 0, 255, .5);
  }

  97%, 100% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.socmed {
  transform-style: preserve-3d;
  position: absolute;
  bottom: .3rem;
  left: .5rem;
  transform: translateZ(1px);
}

@media screen and (max-width: 600px) {
  .socmed {
    bottom: -3.5rem;
    left: -.5rem;
  }
}

.socmed a {
  will-change: transform;
  padding: .5rem;
  display: inline-block;
  transform: translateZ(1px);
}

.socmed a:hover {
  background: none;
}

.no-compat .socmed a, .safari-compat .socmed a {
  transition: transform .125s linear;
}

.no-compat .socmed a:hover, .safari-compat .socmed a:hover {
  transform: translateZ(20px);
}

.socmed svg {
  cursor: pointer;
  fill: #3c3c3c;
  width: 30px;
  height: 30px;
  transform: translateZ(1px);
}

@media (prefers-color-scheme: dark) {
  .socmed svg {
    fill: #fff;
  }
}

.socmed svg:hover {
  fill: #6f6f6f;
}

@media (prefers-color-scheme: dark) {
  .socmed svg:hover {
    fill: #fff;
  }
}

.socmed .socmed__xing svg, .socmed .socmed__linkedin svg, .socmed .socmed__github svg {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: .225s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.socmed .socmed__xing svg {
  animation-delay: 5.2s;
}

.socmed .socmed__linkedin svg {
  animation-delay: 5.425s;
}

.socmed .socmed__github svg {
  animation-delay: 5.65s;
}

@keyframes distortRGB {
  0%, 93% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }

  95% {
    text-shadow: 6px 0 rgba(255, 0, 0, .5), 3px 0 rgba(0, 255, 0, .5), -3px 0 rgba(0, 0, 255, .5);
  }

  97%, 100% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.vcard {
  transform-style: preserve-3d;
  position: absolute;
  top: .3rem;
  right: .5rem;
  transform: translateZ(1px);
}

@media screen and (max-width: 600px) {
  .vcard {
    top: initial;
    bottom: -3.5rem;
    right: -.5rem;
  }
}

.vcard button {
  cursor: pointer;
  will-change: transform;
  outline: 0;
  padding: .5rem;
  display: inline-block;
  transform: translateZ(1px);
}

.no-compat .vcard button, .safari-compat .vcard button {
  transition: transform .125s linear;
}

.no-compat .vcard button:hover, .safari-compat .vcard button:hover {
  transform: translateZ(20px);
}

.vcard svg {
  cursor: pointer;
  fill: #3c3c3c;
  width: 30px;
  height: 30px;
  transform: translateZ(1px);
}

@media (prefers-color-scheme: dark) {
  .vcard svg {
    fill: #fff;
  }
}

.vcard svg:hover {
  fill: #6f6f6f;
}

@media (prefers-color-scheme: dark) {
  .vcard svg:hover {
    fill: #fff;
  }
}

.vcard__button {
  cursor: pointer;
  background: none;
  border: 0;
}

.vcard__button svg {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: .225s;
  animation-timing-function: ease-in;
  animation-delay: 6.2s;
  animation-fill-mode: forwards;
}

.vcard__button:hover {
  background-color: rgba(0, 0, 0, 0);
}

@keyframes distortRGB {
  0%, 93% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }

  95% {
    text-shadow: 6px 0 rgba(255, 0, 0, .5), 3px 0 rgba(0, 255, 0, .5), -3px 0 rgba(0, 0, 255, .5);
  }

  97%, 100% {
    text-shadow: 0 0 rgba(255, 0, 0, .5), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .5), 0 0 rgba(255, 0, 0, .25), 0 0 rgba(0, 255, 0, .5), 0 0 rgba(0, 0, 255, .25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.meta-navigation {
  z-index: 2;
  text-align: center;
  font-size: .9rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.meta-navigation li {
  background-color: #fff;
  margin-left: .5rem;
  display: inline-block;
}

@media (prefers-color-scheme: dark) {
  .meta-navigation li {
    background-color: #3c3c3c;
  }
}

.meta-navigation li a {
  padding: .5rem .8rem;
  display: block;
}

.meta-navigation li button {
  padding: .5rem .8rem;
  font-size: .9rem;
  display: block;
}

/*# sourceMappingURL=index.153097a7.css.map */
