@import "./vars";

@keyframes distortRGB {
  0%, 93% {
    //color: rgba($color-text, 1);
    text-shadow: 0 0 0 rgba(255,0,0,0.5), 0 0 0 rgba(0,255,0,0.5), 0 0 0 rgba(0,0,255,0.5),
    0 0 0 rgba(255,0,0,0.25), 0 0 0 rgba(0,255,0,0.5), 0 0 0 rgba(0,0,255,0.25);
  }
  95% {
    //color: rgba($color-text, 0);
    text-shadow: 6px 0px 0 rgba(255,0,0,0.5), 3px 0px 0 rgba(0,255,0,0.5), -3px 0px 0 rgba(0,0,255,0.5);
    //,
    //    3px 3px 3px rgba(255,0,0,0.25), -3px 3px 3px rgba(0,255,0,0.5), 3px -3px 3px rgba(0,0,255,0.25)
  }
  97%, 100% {
    //color: rgba($color-text, 1);
    text-shadow: 0 0 0 rgba(255,0,0,0.5), 0 0 0 rgba(0,255,0,0.5), 0 0 0 rgba(0,0,255,0.5),
    0 0 0 rgba(255,0,0,0.25), 0 0 0 rgba(0,255,0,0.5), 0 0 0 rgba(0,0,255,0.25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin glowShadow {
  box-shadow: 0 0 20px rgba(#fff, 0.1);
}

@mixin darkMode {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}