@import 'webfonts';
@import 'util';
@import 'vars';

*, *:before, *:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  font-weight: 300;

  color: $color-text;
  @include darkMode {
    color: $color-planes;
    background-color: $color-text;
  }
}

strong {
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}


a {
  color: $color-text;
  @include darkMode {
    color: $color-planes;
  }
  text-decoration: none;

  &:hover {
    background-color: $color-text;
    color: $color-planes;
    @include darkMode {
      background-color: $color-planes;
      color: $color-text;
    }
  }
}

button {
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  border: 0;
  cursor: pointer;

  outline: none;
  &:focus {
    outline: 1px;
  }

  background-color: $color-planes;
  color: $color-text;

  @include darkMode {
    background-color: $color-text;
    color: $color-planes;
  }

  &:hover {
    background-color: $color-text;
    color: $color-planes;
    @include darkMode {
      background-color: $color-planes;
      color: $color-text;
    }
  }
}


.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}

.app {
  &--static-viewport {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    margin-bottom: 0;
  }
}

.page {
  margin: 1rem auto;

  background-color: $color-planes;
  @include darkMode {
    background-color: $color-text;
  }

  &__content {
    width: 100%;
    max-width: 700px;
    padding: 1rem;
    margin: 0 auto;

    @media screen and ($breakpoint) {
      border: 0;
    }
  }

  h1 {
    font-size: 1.75rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h1, h2, h3, h4, h5 {
    margin: 1rem 0;
  }

  p {
    margin: 0.5rem 0;
  }

  ul {
    margin-left: 1.5rem;

    li {

    }
  }

  &__go-back {
    text-align: center;

    a {
      display: inline-block;
      padding: 0.5rem 0.8rem;
    }

    padding-bottom: 0.5rem;
  }

  //&-enter {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  opacity: 0;
  //  z-index: 1;
  //  &.page-enter-active {
  //    opacity: 1;
  //    transition: opacity 750ms ease-in;
  //  }
  //}
  //
  //&-leave {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  opacity: 1;
  //  z-index: 1;
  //  &.page-leave-active {
  //    opacity: 0;
  //    transition: opacity 750ms ease-in;
  //  }
  //}

  &-enter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    opacity: 0;
    z-index: 1;

    &.page-enter-active {
      transform: translateY(0);
      opacity: 1;
      transition: all 750ms linear;
    }
  }

  &-leave {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0%);
    //opacity: 1;
    z-index: 1;
    &.page-leave-active {
      transform: translateX(100%);
      //opacity: 0;
      transition: all 750ms linear;
    }
  }
}

.fade {
  &-enter {
    display: inline-block;
    width: 0;
    &.fade-enter-active {
      width: 60px;
      transition: width 250ms linear;
    }
  }
}