@import "../vars";
@import "../util";

.socmed {
  position: absolute;
  bottom: .3rem;
  left: 0.5rem;
  transform: translateZ(1px);
  transform-style: preserve-3d;

  @media screen and ($breakpoint) {
    left: -.5rem;
    bottom: -3.5rem;
  }

  a {
    display: inline-block;
    padding: 0.5rem;

    &:hover {
      background: transparent;
    }

    transform: translateZ(1px);
    will-change: transform;

    .no-compat &,
    .safari-compat & {
      transition: transform 125ms linear;

      &:hover {
        transform: translateZ(20px);
      }
    }

  }

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transform: translateZ(1px);

    fill: $color-text;
    @include darkMode {
      fill: $color-planes;
    }

    &:hover {
      fill: lighten($color-text, 20%);
      @include darkMode {
        fill: $color-planes;
      }
    }
  }

  $baseDelay: 5.2s;
  $animationDuration: 225ms;

  .socmed__xing, .socmed__linkedin, .socmed__github {
    svg {
      animation-name: fadeIn;
      animation-fill-mode: forwards;
      animation-duration: $animationDuration;
      animation-timing-function: ease-in;

      opacity: 0;
    }
  }

  .socmed__xing {
    svg {
      animation-delay: $baseDelay;
    }
  }

  .socmed__linkedin {
    svg {
      animation-delay: $baseDelay + $animationDuration;
    }
  }

  .socmed__github {
    svg {
      animation-delay: $baseDelay + $animationDuration*2;
    }
  }
}

