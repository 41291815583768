@import "../vars";
@import "../util";

.vcard {
  position: absolute;
  top: .3rem;
  right: 0.5rem;
  transform: translateZ(1px);
  transform-style: preserve-3d;

  @media screen and ($breakpoint) {
    top: initial;
    right: -.5rem;
    bottom: -3.5rem;
  }

  button {
    display: inline-block;
    padding: 0.5rem;
    cursor: pointer;

    outline: 0;
    transform: translateZ(1px);

    will-change: transform;

    .no-compat &,
    .safari-compat & {
      transition: transform 125ms linear;

      &:hover {
        transform: translateZ(20px);
      }
    }

  }

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transform: translateZ(1px);

    fill: $color-text;

    @include darkMode {
      fill: $color-planes;
    }

    &:hover {
      fill: lighten($color-text, 20%);
      @include darkMode {
        fill: $color-planes;
      }
    }
  }

  $baseDelay: 6.2s;
  $animationDuration: 225ms;

  &__button {
    background: transparent;
    border: 0;
    cursor: pointer;

    svg {
      animation-name: fadeIn;
      animation-fill-mode: forwards;
      animation-duration: $animationDuration;
      animation-timing-function: ease-in;
      animation-delay: $baseDelay;

      opacity: 0;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

