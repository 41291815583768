@import "../vars";
@import "../util";

.meta-navigation {
  position: fixed;
  z-index: 2;
  bottom: 1rem;
  right: 1rem;

  text-align: center;

  font-size: 0.9rem;

  li {
    display: inline-block;
    margin-left: 0.5rem;

    background-color: $color-planes;
    @include darkMode {
      background-color: $color-text;
    }

    a {
      display: block;
      padding: 0.5rem 0.8rem;
    }
    button {
      display: block;
      padding: 0.5rem 0.8rem;
      font-size: 0.9rem;
    }
  }
}