@import "../vars";
@import "../util";

@function fadeIn3D($delay) {
  @return fadeIn3D 2s linear $delay 1 forwards;
}

@keyframes fadeIn3D {
  0% {
    transform: translateZ(200px);
    opacity: 0;
  }
  100% {
    transform: translateZ(1px);
    opacity: 1;
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blurBackgroundIn {
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(2px);
  }
}

@keyframes cardRotateIn {
  0% {
    transform: translate(-50%, -50%) rotateX(65deg) rotateY(-65deg) rotateZ(85deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
}

@keyframes cardRotateInSafari {
  0% {
    transform: translate(-50%, -50%) rotateX(65deg) rotateY(-65deg) rotateZ(85deg) translateZ(-200px);
  }
  100% {
    transform: translate(-50%, -50%) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateZ(-200px);
  }
}

.mouse-catcher {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  //todo: ms edge has jumpy perspective at the begining of the animation, adding both perspectives from catcher and wrapper
  // add this perspective for IE when intro animation has finished
  perspective: 800px;

  transform-style: preserve-3d;
}

.business-card {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 490px;
  height: 240px;
  padding: 1rem;

  @media screen and ($breakpoint) {
    width: 250px;
    height: 350px;
  }

  //backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%) rotateX(0deg) rotateY(0deg) rotateZ(0deg);

  //@media screen and (orientation: landscape) and (max-width: 800px) {
  //  top: 40%;
  //}

  &__backdrop-filter {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border: 1px solid $color-lines;

    @include darkMode {
      border: 1px solid $color-planes;
      @include glowShadow;
    }

    background: rgba($color-planes, 0.70);
    @include darkMode {
      background: rgba($color-text, 0.80);
    }

    @supports (backdrop-filter: blur(2px)) {
      background: rgba($color-planes, .25);
      @include darkMode {
        background: rgba($color-text, .7);
      }
      animation-fill-mode: forwards;
      animation-name: blurBackgroundIn;
      animation-duration: 1s;
      animation-delay: 4.2s;
      animation-timing-function: ease-in-out;
    }
  }


  &__name {
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  .business-card__name, .business-card__title, .business-card__phone, .business-card__mail {
    transform: translateZ(1px);
  }

  &__contact {
    text-align: right;
    position: absolute;
    bottom: 0.8rem;
    right: 1rem;
    transform-style: inherit;

    a {
      display: block;
    }
  }

}

.background {
  background-image: url(../../assets/background.svg);
  @include darkMode {
    background-image: url(../../assets/background-dark.svg);
    background-color: $color-text;
  }
  background-repeat: repeat;

  position: absolute;
  top: -700px;
  bottom: -700px;
  left: -700px;
  right: -700px;

  &--safari {
    transform: translateZ(-200px);
  }
}

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .no-compat &,
  .edge-compat &,
  .ie-compat & {
    perspective: 800px;
    transform-origin: center center -200px;
  }

  transform-style: preserve-3d;
}

.run-intro {
  .wrapper {
    animation-fill-mode: forwards;
    animation-name: cardFadeIn;
    animation-duration: 1s;
  }

  .background {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 2.2s;
    animation-timing-function: ease-in-out;
  }

  .business-card {
    animation-fill-mode: forwards;
    animation-name: cardRotateIn;
    animation-duration: 4.35s;
    animation-timing-function: ease-in-out;
  }

  .business-card__backdrop-filter {
    animation-delay: 4.2s;
  }

  .business-card__name, .business-card__title, .business-card__phone, .business-card__mail {
    animation-fill-mode: forwards;
    animation-name: fadeIn3D;
    animation-duration: 2s;
    opacity: 0;
  }

  .business-card__name {
    animation-delay: 1s;
  }

  .business-card__title {
    animation-delay: 1.5s;
  }

  .business-card__phone {
    animation-delay: 2s;
  }

  .business-card__mail {
    animation-delay: 2.5s;
  }
}

.wrapper, .background {
  will-change: transform;
}